import React from 'react';

const LinkedIn = (props) => (
  <svg
    className="linkedin"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 17"
  >
    <path d="M17 17h-4.4v-6.3c0-1.9-.9-1.9-1.2-1.9-.8 0-1.2.7-1.3 1-.1.1-.1.4-.1.7V17H5.6v-.5-8.4-2.9h4.5v.6c.6-.5 1.4-.9 2.6-.9 2.7 0 4.4 2.1 4.4 5.4V17zm-3.4-1H16v-5.7c0-2.8-1.3-4.4-3.4-4.4-1.5 0-2.2.8-2.7 1.5L9 9V6.2H6.6V16H9v-5.5c0-.5 0-.8.1-1.1.4-1 1.2-1.6 2.2-1.6.7 0 2.2.3 2.2 2.9V16zm-9 1H.2V5.2h4.4V17zm-3.4-1h2.4V6.2H1.2V16zM2.4 4.7C1 4.7 0 3.7 0 2.3S1-.1 2.4-.1s2.4 1 2.4 2.4c.1 1.4-1 2.4-2.4 2.4zm0-3.7C1.5 1 1 1.5 1 2.4c0 .8.6 1.4 1.4 1.4.9 0 1.5-.5 1.5-1.4C3.8 1.5 3.3 1 2.4 1z" />
  </svg>
);

export default LinkedIn;
