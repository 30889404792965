import React from 'react';
import styled from 'styled-components';

import { linearGradient, cover } from 'polished';
import { constrain, at } from '../../helpers/css';
import { jssValue, randomInteger } from '../../helpers/jss';

import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';

import RouteLink from '../RouteLink';
import DualImage from '../DualImage';
import { Parallax } from 'react-parallax';

const randomBackground = (backgrounds) => {
  const min = 0,
    max = backgrounds.length - 1;

  return backgrounds[randomInteger(min, max)];
};

const getBackgroundField = (item) => {
  const {
    fields: { background },
  } = item;

  return background;
};

const ImageTextBlock = (props) => {
  const fieldValue = jssValue(props.fields);

  const {
      fields: { title, richText, image, button, backgrounds },
    } = props,
    background = getBackgroundField(randomBackground(backgrounds));

  return (
    <StyledImageTextBlock>
      <Parallax
        className="global-padding-all"
        bgImage={background.value.src}
        bgImageAlt={background.value.alt}
        strength={300}>
        <div className="content">
          <header>
            <Text tag="h2" className="h1" field={title} />
            <RichText field={richText} />
          </header>
          {image && image.value && (
            <DualImage
              image={fieldValue('image')}
              mobile={fieldValue('imageMobile')}
            />
          )}
        </div>
        {button && <RouteLink className="btn" field={button} />}
      </Parallax>
    </StyledImageTextBlock>
  );
};

const StyledImageTextBlock = styled.article`
  position: relative;
  text-align: center;

  .content {
    position: relative;
    ${constrain()};
    color: #fff;
    z-index: 2;

    img {
      margin-top: 50px;
      width: 100%;
    }
  }

  .btn {
    position: relative;
    margin-top: 100px;
    z-index: 2;

    ${at('tablet')} {
      margin-top: 50px;
    }
  }

  .react-parallax {
    &::before {
      content: '';
      ${cover()};
      z-index: 1;
      background-image: ${({ theme }) =>
        linearGradient({
          colorStops: [`${theme.colors.blue} 20%`, 'transparent'],
          toDirection: 'to bottom',
          fallback: 'transparent',
        })};
    }

    img {
      max-width: none !important;
    }
  }
`;

export default ImageTextBlock;
