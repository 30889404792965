import React from 'react';
import styled from 'styled-components';

import { Image } from '@sitecore-jss/sitecore-jss-react';
import { cover, position } from 'polished';

const BackgroundImage = ({ image, position, parallax }) => {
  return (
    <StyledBackgroundImage
      className="BackgroundImage"
      position={position}
      parallax={parallax}>
      <Image media={image} />
    </StyledBackgroundImage>
  );
};

const StyledBackgroundImage = styled.picture`
  ${cover()};
  overflow: hidden;
  clip-path: ${({ parallax }) => (parallax ? 'inset(0)' : 'none')};

  img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    object-position: ${({ position }) => position || 'center'};
    ${({ parallax }) => (parallax ? position('fixed', 0, 0, 0, 0) : 'static')};
  }

  ~ * {
    position: relative;
    z-index: 2;
  }
`;

export default BackgroundImage;
