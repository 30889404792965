import React from 'react';
import styled from 'styled-components';

const Textarea = ({ label, id, ...rest }) => {
  return (
    <StyledTextarea className="form-item">
      <label className="sr-text" htmlFor={id}>
        {label}
      </label>
      <textarea id={id} {...rest} />
    </StyledTextarea>
  );
};

const StyledTextarea = styled.div`
  + .form-item,
  + button {
    margin-top: 24px;
  }
`;

export default Textarea;
