import React from 'react';
import styled from 'styled-components';

import { position, size } from 'polished';

import RouteLink from '../RouteLink';

const Icon = ({ link, children }) => {
  return (
    <StyledIcon className="icon">
      <RouteLink field={link}>{children}</RouteLink>
    </StyledIcon>
  );
};

const StyledIcon = styled.div`
  ${size(36)};

  & > a {
    position: relative;
    display: block;
    height: 100%;
    transition: ${({ theme }) => theme.t()};
  }

  img {
    ${position('absolute', '50%', null, null, '50%')};
    transform: translate(-50%, -50%);
    width: 60%;
  }
`;

export default Icon;
