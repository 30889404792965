import React from 'react';

const FNC3 = (props) => {
  return (
    <svg
      id="fully-networked-c3-sector-icon"
      className="sector-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 89.3 77.4"
      xmlSpace="preserve"
    >
      <path d="M66.9 77.4H22.3L0 38.7 22.3 0h44.6l22.4 38.7-22.4 38.7zm-43.1-2.7h41.6l20.8-36-20.8-36H23.8L3 38.7l20.8 36z" />
      <path d="M18.9 31.1h11.7v3.1h-7.7v3.4h6.7v3.1h-6.7v6.5h-4V31.1zM32.1 31.1h3.5l6.8 9.6v-9.6h4v16.1h-3.5l-6.8-9.6v9.6h-4V31.1zM50.8 45.3c-1.5-1.6-2.4-3.8-2.2-6-.1-2.3.6-4.5 2.2-6.2 1.5-1.4 3.6-2.2 6.5-2.2.9 0 1.7.1 2.6.2.8.1 1.5.3 2.3.6v3.5c-1.4-.6-3-.9-4.6-.9-1.3-.1-2.7.3-3.7 1.2-.9 1.1-1.3 2.4-1.2 3.8-.1 1.3.3 2.6 1.2 3.6 1 .8 2.3 1.3 3.7 1.2 1.6 0 3.1-.3 4.5-.9v3.5c-1.6.5-3.2.8-4.9.8-2.4.1-4.7-.7-6.4-2.2zM64.6 34.3c-.5-.1-.9-.2-1.4-.3v-2c.4.2.8.3 1.2.4.4.1.9.1 1.3.1.5 0 1-.1 1.4-.2.3-.2.5-.5.4-.9 0-.3-.1-.6-.4-.7-.3-.2-.7-.3-1.1-.2h-1.5l.2-1.6H66c.4 0 .8-.1 1.1-.2.2-.2.3-.5.3-.7 0-.3-.1-.6-.4-.8-.4-.2-.8-.3-1.3-.3-.4 0-.8 0-1.3.1-.4.1-.8.2-1.1.4v-1.9c.4-.2.8-.3 1.3-.4.5-.1 1-.1 1.5-.1.7 0 1.4.1 2 .3.5.2.9.5 1.2.9.3.4.4.9.4 1.4 0 .4-.1.9-.3 1.3-.2.4-.5.7-.9.8.4.1.8.4 1 .8s.3.8.3 1.3c.1.8-.3 1.6-1 2.1-.8.5-1.8.8-2.8.7-.3-.2-.9-.3-1.4-.3z" />
    </svg>
  );
};

export default FNC3;
