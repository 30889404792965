import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import { linearGradient, cover, rgba } from 'polished';
import { constrain, clamp } from '../../helpers/css';

import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import BackgroundImage from '../BackgroundImage';
import SectorIcon from '../SectorIcon';
import ScrollTop from '../ScrollTop';

import { useDidMount, useEventListener } from '../../helpers/hooks';

const maxOffset = -150;

const SectorHero = (props) => {
  const {
    fields: {
      sectorItem: {
        fields: { title, text, icon, background },
      },
    },
    sitecoreContext: { pageEditing },
  } = props;

  const [offset, setOffset] = useState(maxOffset);

  // Memoize useEffect dependency
  const handleScroll = useCallback(() => {
    let newOffset = Math.floor(offset + window.pageYOffset / 4);

    if (newOffset > 0) {
      return setOffset(0);
    }

    if (newOffset < maxOffset) {
      return setOffset(maxOffset);
    }

    return setOffset(newOffset);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const hasMounted = useDidMount();

  useEventListener(
    'scroll',
    !pageEditing ? handleScroll : () => console.log('Experience Editor')
  );

  return (
    <StyledSectorHero
      hasMounted={hasMounted}
      offset={offset}
      className="global-padding-all -large">
      <BackgroundImage image={background} />
      <div className="content">
        <header>
          <SectorIcon icon={icon.value} />
          <Text tag="h1" className="h1" field={title} />
          <Text tag="p" className="-large" field={text} />
        </header>
      </div>
      <ScrollTop />
    </StyledSectorHero>
  );
};

const StyledSectorHero = styled.article`
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .content {
    ${constrain(680)};
    color: #fff;
    fill: #fff;
    text-shadow: ${({ theme }) => theme.shadows.text};

    .sector-icon,
    h1,
    p {
      opacity: ${({ hasMounted }) => (hasMounted ? 1 : 0)};
      transform: ${({ hasMounted }) =>
        hasMounted ? 'none' : 'translateY(20px)'};
      transition: ${({ theme }) => theme.t()};
    }

    /* sector icon */
    .sector-icon {
      ${constrain(64)};
      margin-bottom: 10px;
      transition-delay: 0.1s;
    }

    h1 {
      ${constrain(550)};
      transition-delay: 0.2s;
    }

    p {
      line-height: 1.33;
      font-weight: ${({ theme }) => theme.fontweights.bold};
      transition-delay: 0.3s;
    }
  }

  > .BackgroundImage {
    &::before {
      content: '';
      ${cover()};
      background-image: ${({ theme }) =>
        linearGradient({
          colorStops: [
            theme.colors.blue,
            rgba(theme.colors.blueTransparent, 0),
            theme.colors.blue,
          ],
          toDirection: 'to bottom',
          fallback: 'transparent',
        })};
    }
  }

  + .TextBlock {
    .content {
      ${constrain(680)};
      position: relative;
      padding: ${clamp(20, 55)};
      background-color: #fff;
      transform: ${({ offset, hasMounted }) =>
        hasMounted ? `translateY(${offset}px)` : 'none'};
      transition: transform 0.5s ease-out;
    }
  }
`;

export default withSitecoreContext()(SectorHero);
