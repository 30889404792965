export default {
  fonts: {
    header: 'Kanit',
    body: 'Roboto',
    condensed: 'Roboto Condensed',
  },
  fontweights: {
    light: 300,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  colors: {
    gray: '#959595',
    brown: '#39342d',
    green: '#72951c',
    green1: '#94d100',
    blue: '#002855',
    blue1: '#5d7890',
    blueTransparent: '#00518e',
    blueTransparent2: '#00437a',
  },
  breakpoints: {
    tablet: 768,
    desktop: 1440,
  },
  innermaxwidth: 950,
  shadows: {
    text: '0 2px 4px rgba(0, 0, 0, .5)',
    box: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
  },
  t: (duration = '1s', delay = '0s') => `${duration} cubic-bezier(0,.99,.53,.99) ${delay}`
};
