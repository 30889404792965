import React from 'react';
import styled from 'styled-components';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const typeMap = new Map([
  ['podcast', 'blue'],
  ['media', 'green1'],
  ['blog', 'blue1'],
]);

const Tag = ({ text, color }) => {
  // if there's a type color, use that, otherwise use provided color, otherwise fallback to gray
  const typeColor = typeMap.get(text.value.toLowerCase()) || color || 'gray';

  return (
    <StyledTag className="Tag" color={typeColor}>
      <Text field={text} />
    </StyledTag>
  );
};

const StyledTag = styled.div`
  display: inline-block;
  padding: 6px 12px;
  min-width: 67px;
  font-family: ${({ theme }) => theme.fonts.condensed};
  font-weight: ${({ theme }) => theme.fontweights.bold};
  text-transform: uppercase;
  text-align: center;
  font-size: 12px;
  color: #fff;
  background-color: ${({ theme, color }) => theme.colors[color] || color};
  border-radius: 50px;
`;

export default Tag;
