import React from 'react';

const Facebook = (props) => (
  <svg
    className="facebook"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 17"
    xmlSpace="preserve">
    <path d="M14.1 17H11V9.8h2.1c0-.1 0-.3.1-.4v-.3c0-.3.1-.5.1-.8v-.1h-1.6c-.3 0-.7-.3-.7-.7V6c.1-1 .6-1.5 1.6-1.5h1V3.2H11.9c-.7 0-1.2.3-1.6.7-.5.5-.7 1.1-.7 2v2.2H7.4v1.5h2.1V17H2.9C1.3 17 0 15.7 0 14.1V2.9C0 1.3 1.3 0 2.9 0h11.2C15.7 0 17 1.3 17 2.9v11.2c0 1.6-1.3 2.9-2.9 2.9zM12 16h2c1.1 0 1.9-.9 1.9-1.9V2.9c.1-1-.8-1.9-1.8-1.9H2.9C1.9 1 1 1.9 1 2.9v11.2c0 1 .9 1.9 1.9 1.9h5.6v-5.2H6.4V8.6c0-1.1.2-1.3 1.3-1.3h.8V6c0-1.2.3-2 1-2.7.6-.6 1.4-1 2.3-1h1.9c.1 0 .4 0 .7.2.2.2.2.6.2.7v1.5c0 .1 0 .4-.2.6-.2.2-.5.2-.6.2h-1.1c-.5 0-.6.1-.6.5v1.1h2.4l-.1 1.2c0 .3-.1.5-.1.8 0 .1 0 .2-.1.3 0 .1-.1.3-.1.4 0 .4-.2.8-.9.8H12V16zm.1-8.5c0 .1 0 .1 0 0 0 .1 0 .1 0 0zm-.3-.1z" />
  </svg>
);

export default Facebook;
