import React from 'react';
import styled from 'styled-components';

import { at, constrain } from '../../helpers/css';

import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';

const TextBlock = (props) => {
  const {
    fields: { title, richText },
  } = props;

  return (
    <StyledTextBlock className="TextBlock global-padding-all">
      <div className="content">
        {title && title.value && (
          <header>
            <Text tag="h2" className="h1" field={title} />
          </header>
        )}
        <RichText className="wysiwyg-content" field={richText} />
      </div>
    </StyledTextBlock>
  );
};

const StyledTextBlock = styled.article`
  background: #fff;

  .content {
    ${constrain()};

    ${at('tablet')} {
      display: flex;
    }
  }

  header {
    color: ${({ theme }) => theme.colors.green};
    margin-bottom: 20px;

    ${at('tablet')} {
      flex: 0 0 300px;
      margin-right: 62px;
    }
  }

  + .LinkTable,
  + .TextBlock,
  + .ContentGroupGrid,
  + .ThreeUp {
    padding-top: 0;
  }
`;

export default TextBlock;
