import React from 'react';
import styled from 'styled-components';

import { dateFormatter } from '../../helpers/jss';

import { hover } from '../../helpers/css';

import { Text, DateField } from '@sitecore-jss/sitecore-jss-react';
import Tag from '../Tag';
import RouteLink from '../RouteLink';

const ConditionalWrapper = ({
  condition,
  wrapperTrue,
  wrapperFalse,
  children,
}) => {
  return condition ? wrapperTrue(children) : wrapperFalse(children);
};

const ContentGroup = ({ link, tag, title, subhead, date }) => {
  return (
    <StyledContentGroup>
      <ConditionalWrapper
        condition={link?.value}
        wrapperTrue={(children) => (
          <RouteLink className="inner" field={link}>
            {children}
          </RouteLink>
        )}
        wrapperFalse={(children) => <div className="inner">{children}</div>}>
        <Tag text={tag} />
        <Text tag="h2" className="h4 title" field={title} />
        {subhead && <Text tag="p" className="subhead" field={subhead} />}
        {date && (
          <DateField
            tag="p"
            className="date"
            field={date}
            render={dateFormatter}
          />
        )}
      </ConditionalWrapper>
    </StyledContentGroup>
  );
};

const StyledContentGroup = styled.div`
  color: ${({ theme }) => theme.colors.blue};

  & > .inner {
    text-decoration: none;
    & > * {
      margin-bottom: 8px !important;
    }

    ${hover()} {
      .title {
        color: ${({ theme }) => theme.colors.green1};
      }
    }
  }

  .title {
    transition: ${({ theme }) => theme.t()};
    color: ${({ theme }) => theme.colors.blue};
  }

  .subhead {
    font-weight: ${({ theme }) => theme.fontweights.medium};
  }

  p {
    color: ${({ theme }) => theme.colors.blue1};
  }
`;

export default ContentGroup;
