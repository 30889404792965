import React from 'react';

const Twitter = (props) => (
  <svg
    className="twitter"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 14.3"
  >
    <path d="M5.5 14.3c-1 0-2-.1-2.9-.4-.4-.1-2.6-.8-2.6-1.5 0-.6.6-.6 1.4-.6.9 0 1.8-.2 2.6-.6-1.1-.5-1.9-1.3-2.2-2.1 0-.1-.1-.4.1-.6l.1-.1c-.4-.4-.8-.8-1-1.2 0 0-.7-1.5-.4-2.1.1-.2.3-.2.5-.2C.6 4 .4 2.8.8 1.6L1.3.3l1.3 1.3c1.5 1.5 3.2 2.3 5.2 2.6-.1-1.7.9-3.3 2.6-3.9C11 .1 12 0 12.7.2c.3.1.8.4 1.1.6l.4.3.4-.1c.3-.1.8-.3.9-.4.4-.2.7-.3.9-.1l.2.1v.3c0 .2-.2.6-.4.9.3-.1.5 0 .6.1.2.3.1.6 0 .7-.1.2-.6.7-.9 1-.5.5-.5.5-.5.9 0 .9-.4 2.7-.8 3.7-.8 1.9-2.4 3.8-4.1 4.8-1.4.9-3.2 1.3-5 1.3zm-3.2-1.6c.2.1.4.2.6.2 2.3.7 5.1.4 7.2-.8 1.5-.9 3-2.7 3.6-4.3.4-.9.7-2.6.7-3.3 0-.7.1-.9.7-1.5-.2 0-.4-.1-.5-.3-.2-.4 0-.7.2-1l-.9.3-.8-.5c-.3-.2-.7-.4-.8-.5-.5-.1-1.3-.1-1.7 0-1.2.5-2 1.6-1.9 2.9l.1 1.2L7.6 5c-2.2-.3-4.2-1.2-5.8-2.9l-.2-.2c-.3 1 0 2.1.7 2.8.2.2.6.6.3 1-.2.3-.6.3-1 .1.1.2.2.5.2.7.2.5.8 1 1.4 1.3l.6.3-.2 1H3c.3.5 1 1 1.7 1.2l1.7.6-1.5.9c-.9.5-1.7.8-2.6.9zm-.8-7.4z" />
  </svg>
);

export default Twitter;
