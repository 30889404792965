import React from 'react';

const ArtificialIntelligence = (props) => {
  return (
    <svg
      id="artificial-intelligence-sector-icon"
      className="sector-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 89.3 77.3"
    >
      <path d="M67 77.3H22.3L0 38.7 22.3 0H67l22.3 38.7L67 77.3zm-43.2-2.6h41.6l20.8-36-20.8-36H23.8L3 38.7l20.8 36z" />
      <path d="M29.3 20.6h9.2l13 34h-8.9l-3-7.6H28.2l-3 7.7h-8.9l13-34.1zm9.1 20.1l-4.5-11.8-4.5 11.8h9zM55.8 20.6h8.5v34h-8.5v-34z" />
    </svg>
  );
};

export default ArtificialIntelligence;
