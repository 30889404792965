import React from 'react';

const MobileMenuIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      xmlSpace="preserve">
      <g transform="translate(272 20)">
        <path d="M-256-20c-8.9 0-16 7.2-16 16s7.1 16 16 16 16-7.1 16-16-7.1-16-16-16zm0 30.1c-7.8 0-14.1-6.3-14.1-14.1s6.3-14.1 14.1-14.1 14.1 6.3 14.1 14.1-6.3 14.1-14.1 14.1z" />
        <circle cx="-263.8" cy="-4" r="2.7" />
        <circle cx="-256" cy="-4" r="2.7" />
        <circle cx="-248.2" cy="-4" r="2.7" />
      </g>
    </svg>
  );
};

export default MobileMenuIcon;
