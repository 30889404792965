import React from 'react';

// sector icons
import ArtificialIntelligence from '../_Icons/ArtificialIntelligence';
import Autonomy from '../_Icons/Autonomy';
import Biotechnology from '../_Icons/Biotechnology';
import Cyber from '../_Icons/Cyber';
import DirectedEnergy from '../_Icons/DirectedEnergy';
import FiveG from '../_Icons/FiveG';
import FNC3 from '../_Icons/FNC3';
import Hypersonics from '../_Icons/Hypersonics';
import Microelectronics from '../_Icons/Microelectronics';
import QuantumScience from '../_Icons/QuantumScience';
import Space from '../_Icons/Space';

const icons = {
  'artificial-intelligence': ArtificialIntelligence,
  autonomy: Autonomy,
  biotechnology: Biotechnology,
  cyber: Cyber,
  'directed-energy': DirectedEnergy,
  'five-g': FiveG,
  'fully-networked-c3': FNC3,
  hypersonics: Hypersonics,
  microelectronics: Microelectronics,
  'quantum-science': QuantumScience,
  space: Space,
};

const SectorIcon = ({ icon }) => {
  const IconComponent = icons[icon];

  return <IconComponent />;
};

export default SectorIcon;
