import React, { useState } from 'react';
import styled from 'styled-components';

import { cover, rgba } from 'polished';
import { clamp, constrain } from '../../helpers/css';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import BackgroundImage from '../BackgroundImage';
import Modal from '../Modal';
import VimeoEmbed from '../VimeoEmbed';

import { useDidMount } from '../../helpers/hooks';

const MediaHero = (props) => {
  const {
    fields: { title, modalVideoId, bgVideoId, image, buttonText },
  } = props;

  const [modalOpen, setModalOpen] = useState(false);

  const hasMounted = useDidMount();

  return (
    <StyledMediaHero className="global-padding-all" hasMounted={hasMounted}>
      <BackgroundImage image={image} />
      {bgVideoId && <VimeoEmbed id={bgVideoId.value} bRoll={true} />}
      <header className="content">
        <Text tag="h1" field={title} />
        {modalVideoId && (
          <Text
            tag="button"
            className="-transparent"
            onClick={() => setModalOpen(true)}
            field={buttonText}
          />
        )}
      </header>
      {modalOpen && (
        <Modal closeModal={() => setModalOpen(false)}>
          <VimeoEmbed id={modalVideoId.value} />
        </Modal>
      )}
    </StyledMediaHero>
  );
};

const StyledMediaHero = styled.article`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
  color: #fff;
  background-color: ${({ theme }) => theme.colors.blue};
  text-align: center;

  > .BackgroundImage {
    &::after {
      content: '';
      ${cover()};
      background-color: ${({ theme }) => rgba(theme.colors.blue, 0.3)};
    }
  }

  > .VimeoEmbed {
    &::after {
      content: '';
      ${cover()};
      background-color: ${({ theme }) => rgba(theme.colors.blue, 0.3)};
      z-index: 0;
    }
  }

  .content {
    ${constrain(545)};

    h1 {
      text-shadow: ${({ theme }) => theme.shadows.text};
      opacity: ${({ hasMounted }) => (hasMounted ? 1 : 0)};
      transform: ${({ hasMounted }) =>
        hasMounted ? 'none' : 'translateY(40px)'};
      transition: ${({ theme }) => theme.t('1s', '.1s')};
    }

    button {
      margin-top: ${clamp(40, 80)};
      opacity: ${({ hasMounted }) => (hasMounted ? 1 : 0)};
      transform: ${({ hasMounted }) =>
        hasMounted ? 'none' : 'translateY(40px)'};
      transition: ${({ theme }) => theme.t('1.3s', '.3s')};
    }
  }
`;

export default MediaHero;
