import React from 'react';
import styled from 'styled-components';

const Input = ({ label, id, ...rest }) => {
  return (
    <StyledInput className="form-item">
      <label className="sr-text" htmlFor={id}>
        {label}
      </label>
      <input id={id} {...rest} />
    </StyledInput>
  );
};

const StyledInput = styled.div`
  + .form-item,
  + button {
    margin-top: 24px;
  }
`;

export default Input;
