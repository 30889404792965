import React from 'react';
import styled from 'styled-components';

import { at, constrain, killBullets, hover } from '../../helpers/css';
import { dateFormatter } from '../../helpers/jss';

import { Text, DateField } from '@sitecore-jss/sitecore-jss-react';

import RouteLink from '../RouteLink';

const mapDateLinks = (item, index) => {
  const {
    fields: { link, date },
  } = item;
  return (
    <li key={index}>
      <DateField tag="p" className="date" field={date} render={dateFormatter} />
      <RouteLink field={link} />
    </li>
  );
};

const LinkTable = (props) => {
  const {
    fields: { title, dateLinks },
  } = props;

  return (
    <StyledLinkTable className="LinkTable global-padding-all">
      <div className="content">
        <header>
          <Text tag="h2" className="h3" field={title} />
        </header>
        {dateLinks && dateLinks.length && (
          <ul>{dateLinks.map(mapDateLinks)}</ul>
        )}
      </div>
    </StyledLinkTable>
  );
};

const StyledLinkTable = styled.article`
  background: #fff;

  .content {
    ${constrain()};
  }

  header {
    color: ${({ theme }) => theme.colors.blue1};
    margin-bottom: 20px;

    ${at('tablet')} {
      margin-right: 62px;
    }
  }

  ul {
    ${killBullets()};

    li {
      display: flex;
      font-family: ${({ theme }) => theme.fonts.condensed};
      font-weight: ${({ theme }) => theme.fontweights.bold};

      p {
        margin: 0;
      }

      .date {
        flex: 0 0 120px;
        color: ${({ theme }) => theme.colors.gray};
        margin-right: 20px;
      }

      a {
        color: ${({ theme }) => theme.colors.blue1};

        ${hover()} {
          color: ${({ theme }) => theme.colors.blue};
        }
      }

      + li {
        margin-top: 24px;
      }
    }
  }

  + .LinkTable,
  + .TextBlock,
  + .ContentGroupGrid,
  + .ThreeUp {
    padding-top: 0;
  }
`;

export default LinkTable;
