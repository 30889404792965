import React from 'react';

const Instagram = (props) => (
  <svg
    className="instagram"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 17"
    xmlSpace="preserve">
    <path d="M14.6 17H2.4C1.1 17 0 15.9 0 14.6V2.4C0 1.1 1.1 0 2.4 0h12.2C15.9 0 17 1.1 17 2.4v12.2c0 1.3-1.1 2.4-2.4 2.4zM2.4 1C1.6 1 1 1.6 1 2.4v12.2c0 .8.6 1.4 1.4 1.4h12.2c.8 0 1.4-.6 1.4-1.4V2.4c0-.8-.6-1.4-1.4-1.4H2.4zm12.1 14.5h-12c-.6 0-1-.4-1-1v-8H5l-.6.8c-.1.2-.3.7-.3 1.2 0 2.4 2 4.4 4.4 4.4s4.4-2 4.4-4.4c0-.5-.1-1-.3-1.2l-.7-.8h3.6v8c0 .6-.4 1-1 1zm-12-8v7h12v-7h-.7c.1.4.1.7.1 1 0 3-2.4 5.4-5.4 5.4-3 0-5.4-2.4-5.4-5.4 0-.1 0-.6.1-1h-.7zm6 4.6c-2 0-3.6-1.6-3.6-3.6S6.5 5 8.5 5s3.6 1.6 3.6 3.6-1.6 3.5-3.6 3.5zm0-6.1C7.1 6 5.9 7.1 5.9 8.5s1.2 2.6 2.6 2.6c1.4 0 2.6-1.2 2.6-2.6C11.1 7.1 9.9 6 8.5 6zm5.5-.5h-1.5c-.6 0-1-.4-1-1V3c0-.6.4-1 1-1H14c.6 0 1 .4 1 1v1.5c0 .6-.4 1-1 1zM12.5 3v1.5H14V3h-1.5z" />
  </svg>
);

export default Instagram;
