import React from 'react';
import styled from 'styled-components';

import { at, radialGradient, hover, constrain } from '../../helpers/css';
import { position } from 'polished';

import { RichText, Placeholder } from '@sitecore-jss/sitecore-jss-react';

import RouteLink from '../RouteLink';
import ContactForm from '../ContactForm';
import Icon from '../Icon';

// assets and icons
import ETILogo from '../ETILogo';
import Twitter from '../_Icons/Twitter';
import LinkedIn from '../_Icons/LinkedIn';
import Facebook from '../_Icons/Facebook';
import Instagram from '../_Icons/Instagram';
import YouTube from '../_Icons/YouTube';
import FooterNDIA from '../../assets/images/ETI-Footer-NDIA.png'
import FooterNTSA from '../../assets/images/ETI-Footer-NTSA.png'
import FooterWID from '../../assets/images/ETI-Footer-WID.png'

const icons = {
    twitter: Twitter,
    linkedin: LinkedIn,
    facebook: Facebook,
    instagram: Instagram,
    youtube: YouTube,
};

const mapUtilityLinks = (item, index) => {
    const {
        fields: { link },
    } = item;
    return <RouteLink key={index} field={link} />;
};

const mapSocialLinks = (item, index) => {
    const {
        fields: { service, link },
    } = item,
        IconNode = icons[service.value];
    return (
        <Icon key={index} link={link}>
            <IconNode />
        </Icon>
    );
};

const SiteFooter = (props) => {
    const {
        fields: {
            contactFormTitle,
            contactFormText,
            address,
            socialLinks,
            utilityLinks,
        },
        hasContactForm,
        rendering,
    } = props;

    return (
        <StyledSiteFooter className="global-padding-h">
            {hasContactForm?.value && (
                <ContactForm title={contactFormTitle} text={contactFormText}>
                    <Placeholder name="jss-forms" rendering={rendering} />
                </ContactForm>
            )}
            <div className="footer-main">
                <div className="footer-main-left">
                    <ETILogo />
                    <RichText field={address} />
                    {socialLinks && socialLinks.length && (
                        <div className="social-links">
                            {socialLinks.map(mapSocialLinks)}
                        </div>
                    )}
                </div>
                <div className="footer-main-right">
                    <div className="footer_aa">
                        <div className="footer_aa_title">Affiliate Associations</div>
                        <div class="footer_aa_links">
                            <a href="https://www.ndia.org/" target="_blank">
                                <img className="footer_aa_img" alt="National Defense Industrial Association" title="National Defense Industrial Association Web Site" src={FooterNDIA} />
                            </a>
                            <a href="https://www.ntsa.org/" target="_blank">
                                <img className="footer_aa_img" alt="National Training and Simulation Association" title="National Training and Simulation Association Web Site" src={FooterNTSA} />
                            </a>
                            <a href="https://www.womenindefense.net/" target="_blank">
                                <img className="footer_aa_img" alt="Women In Defense, A National Security Organization" title="Women In Defense, A National Security Organization Web Site" src={FooterWID} />
                            </a>                           
                        </div>
                    </div>
                    <p>
                        © {new Date().getFullYear()} ETI. All Rights Reserved.{' '}
                        {utilityLinks.map(mapUtilityLinks)}
                    </p>
                </div>
            </div>
        </StyledSiteFooter>
    );
};

const StyledSiteFooter = styled.article`
  grid-area: footer;
  min-height: 250px;
  ${radialGradient(false, true)};
  color: #fff;

  .footer-main {
    padding-top: 40px;
    padding-bottom: 40px;

    ${at(6)} {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    p {
      font-size: 14px;
    }

    &-left {
      ${constrain(170)};
      text-align: center;

      ${at(6)} {
        margin: 0;
        text-align: left;
      }

      & > * {
        margin-bottom: 12px;

        ${at(6)} {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .social-links {
        display: flex;
        align-items: center;
        justify-content: center;

        ${at(6)} {
          justify-content: flex-start;
        }

        .icon {
          & > a {
            ${hover()} {
              svg {
                fill: ${({ theme }) => theme.colors.green1};
              }
            }

            svg {
              ${position('absolute', '50%', null, null, '50%')};
              width: 52%;
              transform: translate(-50%, -50%);
              fill: #fff;
              transition: ${({ theme }) => theme.t()};
            }
          }
        }
      }
    }

    &-right {
      text-align: center;

      ${at(6)} {
        text-align: right;
      }

      & > p {
        font-size: 12px;

        a {
          color: #fff;

          &::before {
            content: '|';
            color: #fff;
            margin: 0 4px;
          }
        }
      }
    }

    .eti-logo {
      fill: #fff;
      margin-bottom: 28px;
    }
  }
  .footer_aa {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    .footer_aa_links {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .footer_aa_img {
      max-width: 80px;
      margin: .5em 0 1em;
    }
    ${at(6)} {
      align-items: flex-end;
      margin-top: 0;
    }
  }
`;

export default SiteFooter;
