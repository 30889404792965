import React from 'react';

const DirectedEnergy = (props) => {
  return (
    <svg
      id="directed-energy-sector-icon"
      className="sector-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 89.3 77.3"
      xmlSpace="preserve"
    >
      <path d="M67 77.3H22.3L0 38.7 22.3 0H67l22.3 38.7L67 77.3zm-43.1-2.6h41.6l20.8-36-20.8-36H23.9l-20.8 36 20.8 36z" />
      <path d="M58.6 19H26.4c-2.2 0-4 1.8-4 4V55.3c0 2.2 1.8 4 4 4h32.2c2.2 0 4-1.8 4.1-4V23c-.1-2.2-1.9-4-4.1-4zM31.7 47c-1.2 0-2.1-1-2.1-2.1s1-2.1 2.1-2.1 2.1 1 2.1 2.1-.9 2.1-2.1 2.1zM51 50.5c-1.2 0-2.1-1-2.1-2.1s1-2.1 2.1-2.1 2.1 1 2.1 2.1-.9 2.1-2.1 2.1zm8.9 4.7c0 .8-.6 1.4-1.4 1.4h-6.2V53c2.6-.7 4-3.4 3.3-6s-3.4-4-6-3.3-4 3.4-3.3 6c.5 1.6 1.7 2.8 3.3 3.3v3.6h-5.5V40.4l-4.9-4.9c1.3-2.3.4-5.3-1.9-6.6-2.3-1.3-5.3-.4-6.6 1.9s-.4 5.3 1.9 6.6c1.5.8 3.2.8 4.7 0l4.1 4.1v15h-3.7V49l-1.9-1.8c1.3-2.3.4-5.3-2-6.5-2.3-1.3-5.3-.4-6.5 2-1.3 2.3-.4 5.3 2 6.5 1.5.8 3.2.8 4.7-.1l1.1 1.1v6.4h-8.7c-.8 0-1.4-.6-1.4-1.3V23c0-.8.6-1.4 1.3-1.4H42v7.1l4.9 4.9c-1.3 2.3-.4 5.3 1.9 6.6 2.3 1.3 5.3.4 6.6-1.9 1.3-2.3.4-5.3-1.9-6.6-1.5-.8-3.2-.8-4.7 0l-4.1-4.1v-6h13.9c.7 0 1.3.6 1.3 1.4v32.2zM35 35.3c-1.2 0-2.2-1-2.2-2.1s1-2.2 2.1-2.2c1.2 0 2.2 1 2.2 2.1 0 1.3-.9 2.2-2.1 2.2zm16.1-1.4c1.2 0 2.2.9 2.2 2.1s-.9 2.2-2.1 2.2c-1.2 0-2.2-.9-2.2-2.1V36c0-1.2.9-2.1 2.1-2.1z" />
    </svg>
  );
};

export default DirectedEnergy;
