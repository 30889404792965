import { getFieldValue } from '@sitecore-jss/sitecore-jss-react';

export const jssValue = (fields) => (value) => getFieldValue(fields, value);

export const mapProp = (prop, mapperFn) => {
  if (prop && prop.length) {
    return prop.map(mapperFn);
  }
  return null;
};

export const dateFormatter = (date) => {
  const month = date.getMonth() + 1,
    day = date.getDate(),
    year = date.getFullYear();

  return `${leadingZero(month)}.${leadingZero(day)}.${year}`;
};

export const isWindow = () => {
  return typeof window !== 'undefined';
};

// min and max are inclusive
export const randomInteger = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
};

// helpers
function leadingZero(num) {
  return num < 10 ? `0${num}` : num;
}
