import React from 'react';

const YouTube = (props) => (
  <svg
    className="youtube"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 12.2"
    xmlSpace="preserve">
    <path d="M12 12.2h-.1c-2.9 0-5.8 0-8.7-.1-1.8 0-2.7-.9-3-2.7-.3-2.2-.3-4.4 0-6.6.1-.5.2-1 .5-1.4C1.1.7 1.9.3 2.9.2 3.9.2 5 .1 6 .1L8.3 0l1.6.1c1.3 0 2.7.1 4.1.1 1.7.1 2.7.9 2.9 2.7.2 1.7.3 3.5.1 5.6 0 .6-.2 1.2-.3 1.8-.3 1-1 1.6-2.1 1.8-1 .1-1.8.1-2.6.1zM8.3 1l-2.2.1c-1.1 0-2.1.1-3.2.1-.7 0-1.1.2-1.4.7-.1.3-.2.7-.3 1-.3 2.1-.3 4.3 0 6.4.2 1.4.6 1.8 2 1.9 2.9.1 5.9.1 8.7.1h.1c.7 0 1.5 0 2.4-.1.7-.1 1.1-.4 1.2-1 .2-.5.2-1.1.3-1.6.2-2 .1-3.8-.1-5.4-.2-1.2-.7-1.7-1.9-1.8-1.4-.1-2.7-.1-4-.1L8.3 1zM6.4 9.2V3.1l5.7 3-.8.5-2.4 1.2-2.5 1.4zm1-4.4v2.8l1-.6c.5-.3 1-.6 1.6-.8L7.4 4.8z" />
  </svg>
);

export default YouTube;
