import React from 'react';
import styled from 'styled-components';

import { size } from 'polished';
import { at, constrain } from '../../helpers/css';

import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { mapProp } from '../../helpers/jss';

import BackgroundImage from '../BackgroundImage';

const itemMapper = (item, index) => {
  const {
    fields: { name, proTitle, text, image },
  } = item;

  return (
    <article key={index} className="grid-item">
      <div className="image">
        <BackgroundImage image={image} />
      </div>
      <header>
        <Text tag="h3" className="name h4" field={name} />
        <Text tag="h4" className="pro-title h5" field={proTitle} />
      </header>
      <Text tag="p" field={text} />
    </article>
  );
};

const ThreeUp = (props) => {
  const {
    fields: { title, richText, items },
  } = props;

  return (
    <StyledThreeUp className="ThreeUp global-padding-all">
      <div className="content">
        <header className="wysiwyg-content">
          {title?.value && <Text tag="h1" className="h2" field={title} />}
          {richText?.value && <RichText field={richText} />}
        </header>
        <section className="grid">{mapProp(items, itemMapper)}</section>
      </div>
    </StyledThreeUp>
  );
};

const spacing = 25,
  single = `${spacing}px`,
  double = `${2 * spacing}px`;

const StyledThreeUp = styled.article`
  text-align: center;

  .content {
    ${constrain()};

    header {
      ${constrain(600)};
    }
  }

  .grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -${single};

    &-item {
      position: relative;
      flex: none;
      margin: ${single};
      width: calc(100% - ${double});
      max-width: 285px;

      &::after {
        content: '';
        display: block;
        margin: 20px auto 15px;
        ${size(10)};
        background: ${({ theme }) => theme.colors.blue1};
      }

      .name {
        color: ${({ theme }) => theme.colors.blue};
        font-weight: ${({ theme }) => theme.fontweights.semibold};
      }

      .name,
      .pro-title {
        font-family: ${({ theme }) => theme.fonts.body};
        margin-bottom: 15px !important;
        text-transform: none;
      }

      .pro-title {
        color: ${({ theme }) => theme.colors.blue1};
        font-weight: ${({ theme }) => theme.fontweights.medium};
      }

      p {
        font-size: 14px;
        line-height: 1.66;
      }

      ${at(5)} {
        width: calc(50% - ${double});
      }

      ${at('tablet')} {
        width: calc(33.33% - ${double});
      }

      .image {
        position: relative;
        padding-bottom: 94%;
        margin-bottom: 25px;
        clip-path: polygon(0 0, 89% 0, 100% 11%, 100% 100%, 0% 100%);
      }
    }
  }

  + .LinkTable,
  + .TextBlock,
  + .ContentGroupGrid,
  + .ThreeUp {
    padding-top: 0;
  }
`;

export default ThreeUp;
