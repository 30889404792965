import theme from '../settings/theme';

export const hover = (focus = true) => {
  return `&:hover, &:active${focus ? ', &:focus' : ''}`;
};

export const radialGradient = (isRed = false, invert = false) => {
  let colorStops = isRed
    ? ['#d02424', '#790909']
    : [theme.colors.blue1, theme.colors.blue];

  if (invert) {
    colorStops = colorStops.reverse();
  }

  return `
    background-color: ${colorStops[0]};
    background-image: radial-gradient(circle at top, ${colorStops.join(', ')});
  `;
};

export const unstyleButton = () => {
  return 'background: none;color: inherit;border: 0;line-height: 1;padding: 0;min-width: 0;';
};

export const at = (value) => {
  let queryValue = null;

  if (typeof value === 'string' && theme.breakpoints[value]) {
    queryValue = theme.breakpoints[value];
  } else {
    value = +value;
    // makes sure it's a valid breakpoint
    if (!Number.isNaN(value) && value > 3 && value < 30) {
      queryValue = value * 100;
    }
  }

  if (queryValue) {
    return `@media (min-width: ${queryValue}px)`;
  }

  throw new Error('Invalid value passed to css helper <at>');
};

export const constrain = (maxWidth = theme.innermaxwidth) => {
  maxWidth = typeof maxWidth === 'string' ? maxWidth : maxWidth + 'px';
  return `
    max-width: ${maxWidth};
    margin-left: auto;
    margin-right: auto;
  `;
};

export const clamp = (minFont, maxFont) => {
  const minWidth = toRem(400);
  const maxWidth = toRem(1200);
  minFont = toRem(minFont);
  maxFont = toRem(maxFont);

  let slope = (maxFont - minFont) / (maxWidth - minWidth),
    yInt = -minWidth * slope + minFont;

  return `clamp(${minFont}rem, ${yInt}rem + ${slope * 100}vw, ${maxFont}rem)`;
};

export const killBullets = () => {
  return 'list-style-type: none; padding: 0; margin: 0;';
};

// helpers
function toRem(px) {
  return px / 16;
}
